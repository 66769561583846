'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ChakraProvider } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import theme from 'styles/theme'
import { Toaster } from 'react-hot-toast'
import { Fonts } from 'styles/foundations'
import { TitleContext } from 'contexts/TitleContext'
import { ParallaxProvider } from 'react-scroll-parallax'
import Script from 'next/script'
import '../styles/globals.css'
type Props = {
  children: ReactNode
}

const RootLayout = ({ children }: Props) => {
  const [queryClient] = useState(() => new QueryClient())
  const [title, setTitle] = useState<string>('')
  return (
    <html lang="en">
      <head>
        <title>Plan Your Finances | Simplify Bill Management, Automate Payments | Lint</title>
        <meta
          name="description"
          content="Streamline your finances with our powerful bill plan app. Save up for your financial commitments, automate payments, get insights into your spending, and enjoy hassle-free financial organization. Try it now!"
        />
        <meta
          name="keywords"
          content="bill plan app, automate payments, bill management, financial organization, streamline bills, insights, budgeting, personal finance"
        />

        <meta
          property="og:title"
          content="Plan Your Finances | Simplify Bill Management, Automate Payments | Lint"
        />
        <meta
          property="og:description"
          content="Streamline your finances with our powerful bill plan app. Save up for your financial commitments, automate payments, get insights into your spending, and enjoy hassle-free financial organization. Try it now!"
        />
        <meta property="og:image" content="/images/hero-image-compressed.png" />
        <meta property="og:url" content="https://www.lint.finance" />
        <link rel="icon" href="/icons/fav.png" />
      </head>
      {/* Google tag (gtag.js) */}
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-3KXZ9PZ64J" />
      <Script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-3KXZ9PZ64J');
        `}
      </Script>
      <body>
        <ChakraProvider theme={theme}>
          <TitleContext.Provider value={{ title, setTitle }}>
            <ParallaxProvider>
              <Fonts />
              <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
              <Toaster />
            </ParallaxProvider>
          </TitleContext.Provider>
        </ChakraProvider>
      </body>
    </html>
  )
}

export default RootLayout
